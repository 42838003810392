import React from 'react';
import Layout from './src/components/layout';

export function wrapPageElement({ element, props }) {
	if (process.env.NODE_ENV === 'production') {
		window.addEventListener('error', (e) => e.preventDefault());
	}

	return (
		<>
			<Layout {...props}>{element}</Layout>
		</>
	);
}

export const onServiceWorkerUpdateReady = () => {
	const answer = window.confirm(
		'Hi! I just updated my site. Reload to see the latest changes?'
	);

	if (answer === true) {
		window.location.reload();
	}
};
