// React Imports
import React, { useState, useEffect } from 'react';

// Styles
import '../assets/styles/global.sass';

// Components
import Nav from './nav';
import Footer from './footer';

function Layout({ children }) {
	const [lightMode, setLightMode] = useState(false);

	useEffect(() => {
		document.querySelector('html').setAttribute('lang', 'en');
	}, []);

	return (
		<>
			<div id='app' className={lightMode ? 'light-mode' : ''}>
				<Nav {...{ lightMode, setLightMode }} />
				{children}
				<Footer />
			</div>
		</>
	);
}

export default Layout;
