module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-125265218-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-social9-socialshare/gatsby-browser.js'),
      options: {"plugins":[],"content":"55e37ad996a94ec2b6c667b7a8f27a2a","async":true,"defer":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Shodipo Ayomide","short_name":"Shodipo Ayomide","start_url":"/","background_color":"#07040d","theme_color":"#07040d","display":"standalone","icon":"src/images/icon.png","icons":[{"src":"src/images/maskable_icon_192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"src/images/maskable_icon_512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"dc542e6bef85445e71f9cc6d90b75714"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
