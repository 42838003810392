import React from 'react';
import * as styles from '../assets/styles/footer.module.sass';
import * as baseStyles from '../assets/styles/base.module.sass';

function Footer() {
	return (
		<>
			<footer className={styles.footer}>
				<div className={baseStyles.container}>Copyright © 2023 Shodipo Ayomide</div>
			</footer>
		</>
	);
}

export default Footer;
