import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import * as styles from '../assets/styles/nav.module.sass';
import * as baseStyles from '../assets/styles/base.module.sass';
import { Moon, Sun } from './icon';

function Nav({ lightMode, setLightMode }) {
	const [menuOpen, setMenuOpen] = useState(false);

	useEffect(() => {
		document.body.setAttribute('data-theme', !lightMode ? 'dark' : 'light');
	}, [lightMode]);

	const toggleThemeMode = () => {
		setLightMode(!lightMode);
	};

	return (
		<>
			<nav className={styles.nav}>
				<div className={baseStyles.container}>
					<Link to='/' className={styles.logo}>
						Shodipo Ayomide
					</Link>
					<div className={styles.navLinks}>
						<Link to='/'>Home</Link>
						<Link to='/about'>About</Link>
						{/* <a
							href='https://speaking.shodipoayomide.com/'
							target='_blank'
							rel='noreferrer'>
							Speaking
						</a>
						<a
							href='https://www.notion.so/developerayo/Shodipo-Ayomide-Press-Kit-Presenter-Terms-dbc63437aa6e4bd1882dfb4de5223a10'
							target='_blank'
							rel='noreferrer'>
							Press Kit
						</a> */}
						<button
							aria-label='toggle color mode'
							className={styles.colorModeButton}
							onClick={toggleThemeMode}>
							{lightMode === true ? (
								<Moon width={16} />
							) : (
								<Sun width={16} color='#fff' />
							)}
						</button>
					</div>
					<div
						className={
							styles.mobileMenu + (menuOpen ? ' ' + styles.menuOpen : '')
						}>
						<button
							aria-label='toggle color mode'
							onClick={() => setMenuOpen(!menuOpen)}
							className={'mode__menuBar ' + styles.menuBar}>
							<div />
							<div />
						</button>
						<div className={'mode__menu ' + styles.menu}>
							<Link onClick={() => setMenuOpen(false)} to='/'>
								Home
							</Link>
							<Link onClick={() => setMenuOpen(false)} to='/about'>
								About
							</Link>
							{/* <a
								href='https://speaking.shodipoayomide.com/'
								target='_blank'
								rel='noreferrer'>
								Speaking
							</a>
							<a
								href='https://www.notion.so/developerayo/Shodipo-Ayomide-Press-Kit-Presenter-Terms-dbc63437aa6e4bd1882dfb4de5223a10'
								target='_blank'
								rel='noreferrer'>
								Press Kit
							</a> */}
							<button
								className={styles.colorModeButton}
								onClick={toggleThemeMode}>
								{lightMode === true ? (
									<Moon width={16} />
								) : (
									<Sun width={16} color='#fff' />
								)}
							</button>
						</div>
					</div>
				</div>
			</nav>
		</>
	);
}

export default Nav;
